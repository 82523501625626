import * as rive from "@rive-app/canvas";
import Cookies from "js-cookie"; // Install js-cookie with `npm install js-cookie`

export default function riveTodoApp() {
    return {
        files, // Imported from the Twig script
        riveInstances: {}, // Store Rive instances
        todoList: [],
        bannerMessage: "", // Message for the banner
        bannerVisible: false, // Controls banner visibility
        bannerTimeout: null, // Reference to the timeout function
        bannerDuration: 5000, // Duration for banner visibility in milliseconds
        cookieName: "todoProgress", // Name of the cookie to store progress

        init() {
            // Load progress from cookies or initialise the todo list
            const savedProgress = Cookies.get(this.cookieName);
            if (savedProgress) {
                this.todoList = JSON.parse(savedProgress);
            } else {
                this.todoList = this.files.map(file => ({
                    id: file.id,
                    label: file.id,
                    completed: false,
                    isHovered: false, // Tracks hover state
                }));
            }

            // Initialise Rive canvases
            this.files.forEach(file => {
                const canvasElement = document.getElementById(file.id);

                if (canvasElement) {
                    const r = new rive.Rive({
                        src: file.url,
                        canvas: canvasElement,
                        autoplay: true,
                        clearCanvas: false,
                        background: "transparent",
                        stateMachines: ["State Machine 1"], // Adjust if needed
                        onLoad: () => {
                            r.resizeDrawingSurfaceToCanvas();
                            this.riveInstances[file.id] = r;
                        },
                    });
                } else {
                    console.error(`Canvas element with ID "${file.id}" not found.`);
                }
            });
        },

        toggleItem(id) {
            // Mark the item as completed
            const item = this.todoList.find(item => item.id === id);
            if (item && !item.completed) {
                item.completed = true;

                // Save progress to cookies
                this.saveProgress();

                // Show the banner
                this.showBanner(`${item.label} found!`);
            }

            // Optionally trigger animation in Rive
            const instance = this.riveInstances[id];
            if (instance) {
                const inputs = instance.stateMachineInputs("State Machine 1");
                const clickTrigger = inputs.find(input => input.name === "Click");
                if (clickTrigger) clickTrigger.fire();
            }
        },

        hoverItem(id) {
            // Set hover state for the corresponding todo list item
            const item = this.todoList.find(item => item.id === id);
            if (item) {
                item.isHovered = true;
            }
        },

        unhoverItem(id) {
            // Reset hover state for the corresponding todo list item
            const item = this.todoList.find(item => item.id === id);
            if (item) {
                item.isHovered = false;
            }
        },

        saveProgress() {
            // Save the todo list progress to cookies
            Cookies.set(this.cookieName, JSON.stringify(this.todoList), { expires: 7 }); // Expires in 7 days
        },

        clearProgress() {
            // Clear all progress and reset the todo list
            this.todoList = this.files.map(file => ({
                id: file.id,
                label: file.id,
                completed: false,
                isHovered: false,
            }));

            // Clear the cookie
            Cookies.remove(this.cookieName);

            // Optionally save the reset progress
            this.saveProgress();
        },

        showBanner(message) {
            // Set the message and show the banner
            this.bannerMessage = message;
            this.bannerVisible = true;

            // Clear any existing timeout
            if (this.bannerTimeout) clearTimeout(this.bannerTimeout);

            // Set a new timeout to hide the banner
            this.bannerTimeout = setTimeout(() => {
                this.bannerVisible = false;
            }, this.bannerDuration);
        },

        get remainingCount() {
            // Calculate the number of remaining (not completed) items
            return this.todoList.filter(item => !item.completed).length;
        },

        get progressPercentage() {
            // Calculate the percentage of completed items
            if (this.todoList.length === 0) {
                return 0;
            }
            const completedCount = this.todoList.filter(item => item.completed).length;
            return (completedCount / this.todoList.length) * 100;
        },
    };
}
