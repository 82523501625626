/*###########################################################################
// App
//#########################################################################*/

/* CSS
//------------------*/

import "@css/app.css";

/* App
//------------------*/

window.app = {
	disableScroll() {
		document.documentElement.style.overflow = 'hidden'
	},

	enableScroll() {
		document.documentElement.style.overflow = ''
	}
}

/* Vendor
//------------------*/

import '@js/vendor/index'

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
*/
import Alpine from 'alpinejs';
import riveTodoApp from '@/js/components/_riveTodoApp';
import contactForm from "@/js/components/_contactForm";

/* Register components */
Alpine.data('contactForm', contactForm);
Alpine.data('riveTodoApp', riveTodoApp);

Alpine.start();

/* Components
//------------------*/

import '@js/components/index'

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR");
	});
}



